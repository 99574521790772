@mixin responsive ($width) {
	@if $width == mobile {
		@media only screen and (min-width: $w-mobile) {
			@content;
		}
	}
	@else if $width == mobilelandscape {
		@media only screen and (min-width: $w-mobile-landscape) {
			@content;
		}
	}
	@else if $width == tablet {
		@media only screen and (min-width: $w-tablet) {
			@content;
		}
	}
	@else if $width == desktop {
		@media only screen and (min-width: $w-desktop) {
			@content;
		}
	}
	@else if $width == widedesktop {
		@media only screen and (min-width: $w-wide) {
			@content;
		}
	}	
}

@mixin borderbox() {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

@mixin ease-animation {
	-webkit-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000); 
	-moz-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000); 
	-o-transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000); 
	transition: all 200ms cubic-bezier(0.250, 0.100, 0.250, 1.000); /* ease (default) */
}

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin line {
  display: inline-block;
  width: $button-size;
  height: $button-size/7;
  border-radius: $button-size/14; 
  transition: $transition;
}