/*!
	Theme Name: Teatro La Salle Santander
	Theme URI: http://teatrolasalle.es
	Description: Grupo de Teatro La Salle Santander
	Version: 1.0.2
	Author: 7.11 Creative Studio (@711st)
	Author URI: http://711.st
*/

$c--green: #298A36 ;
$c--accent: #B41414;
$c--white: #FFF;
$c--coal: #333;

@mixin iconbefore($icon) {
    &::before {
		font-family: 'FontAwesome';
	    font-style: normal;
		font-size: 24px;
		line-height: 1;
	    content: $icon;
	    speak: none;
	    text-transform: none;
	    line-height: 1;
	    display: inline-block;
        margin-right: 12px;
		position: absolute;
		left: 24px;
    }
}


$fa-font-path: "fonts";
@import "../resources/normalize-css/normalize";
@import "variables.scss";
@import "mixins.scss";
@import "fonts.scss";
@import "magnificpopup.scss";
@import "flexslider.scss";
@import "../resources/font-awesome/scss/font-awesome.scss";

/*------------------------------------*\
    MAIN
\*------------------------------------*/

/* global box-sizing */
*,
*:after,
*:before {
	-moz-box-sizing:border-box;
	box-sizing:border-box;
	-webkit-font-smoothing:antialiased;
	font-smoothing:antialiased;
	text-rendering:optimizeLegibility;
}
/* html element 62.5% font-size for REM use */
html {
	font-size:62.5%;
	height: 100%;
	width: 100%;
}
body {
	font-family: 'Open Sans', sans-serif;
	font-weight: $fw-normal;
	font-size: 1.6rem;
	line-height: 1.4;
	color: $c--coal;
	background: black;
	height: 100%;
	width: 100%;
	margin: 0;
}
/* clear */
.clear:before,
.clear:after {
    content:' ';
    display:table;
}

.clear:after {
    clear:both;
}
.clear {
    *zoom:1;
}
img {
	max-width:100%;
	vertical-align:bottom;
	height: auto;
}
a {
	color:$grey;
	text-decoration:underline;
}
a:hover {
	color:$red;
}
a:focus {
	outline:0;
}
a:hover,
a:active {
	outline:0;
}
input:focus {
	outline:0;
	border:1px solid #04A4CC;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Titillium Web', sans-serif;
	font-weight: $fw-bold;
	color: $red;
}

h1 {
	font-size: 2.4rem;
	margin: 2rem 0 1rem;

	&.page-title {
		@include responsive(tablet) {
			display: none;
		}
	}
}

h2 {
	font-size: 2rem;
	margin: 2rem 0 1rem;
}

h3 {
	font-size: 1.8rem;
	margin: 2rem 0 1rem;
}

/*------------------------------------*\
    STRUCTURE
\*------------------------------------*/



.info {
	margin: 0 auto;
	width: 100%;
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
	padding-top: 100px;

	@include responsive(tablet) {
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 180px;
	}

	&__title {
		width: 90%;
		max-width: 500px;
		margin: 0 auto;
	}

	&__location {
		margin-top: 60px;
		font-size: 32px;
		color: $c--coal;
		@include responsive(tablet) {
			margin-top: 100px;
		}
	}

	&__dates {
		margin: 0;
		padding: 0;
		list-style: none;
		font-size: 18px;
		font-weight: $fw-bold;


		li {
			padding: 4px 0;
		}
	}

	&__buttons {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		margin-top: 60px;
	}

	&__button {
		display: inline-block;
	}

    &__new {
        font-size: 12px;
        color: white;
        padding: 2px 10px;
        font-weight: $fw-bold;
        background: $c--accent;
        border-radius: 20px;
    }

	&__avaiability {
		display: block;
		font-size: 10px;
		color: $c--accent;
	}

	&__music {
		margin-top: 30px;

		@include responsive(tablet) {
			margin-top: 60px;
		}
	}

	&__partners {
		padding-bottom: 80px;
		margin-top: 80px;
	}
}

.button {
	border-radius: 30px;
	background: transparent;
	border: 1px solid $c--accent;
	text-transform: uppercase;
	padding: 12px 24px;
	font-weight: $fw-bold;
	text-decoration: none;
	transition: all 0.25s ease;
	margin: 12px;
	line-height: 24px;
	padding-left: 56px;
	position: relative;

	&.-info {
		display: inline-block;
		color: $c--accent;
		@include iconbefore('\f05a');

		&:hover {
			background: $c--accent;
			color: white;
			transform: scale(1.05);
		}
	}

	&.-trailer {
		display: inline-block;
		color: $c--accent;
		@include iconbefore('\f04b');
		cursor: pointer !important;

		&:hover {
			background: $c--accent;
			color: white;
			transform: scale(1.05);
		}
	}

	&.-tickets {
		display: inline-block;
		color: white;
		background: $c--accent;
		@include iconbefore('\f145');
		margin-bottom: 4px;

		&:hover {
			background: white;
			color: $c--accent;
			border-color: $c--accent;
			transform: scale(1.05);
		}
	}

	&.-disabled {
		cursor: not-allowed;

		&:hover {
			transform: scale(1);
			color: white;
			background: $c--accent;
			border-color: $c--accent;
		}
	}

	&.-spotify {
		display: inline-block;
		color: $c--green;
		border-color: transparent;
		@include iconbefore('\f1bc');

		&:hover {
			background: $c--green;
			color: $c--white;
			border-color: $c--green;
			transform: scale(1.05);
		}
	}

	&::before {
		font-weight: normal;
	}
}

.logo {
	position: absolute;
	left: 8px;
	top: 8px;
	width: 200px;

	@include responsive(tablet) {
		left: 16px;
		top: 16px;
	}
}

.trailer {
	background: rgba(255,255,255, 0.9);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.25s ease;
	z-index: 100;

	&.-is-visible {
		opacity: 1;
		visibility: visible;
	}

	&__close {
		position: fixed;
		top: 8px;
		right: 8px;
		font-size: 36px;
		color: $c--accent;
		opacity: 0.7;
		transition: all 0.25s ease;
		cursor: pointer;

		&:hover {
			opacity: 1;
		}
	}

	&__wrapper {
		width: 90%;
		padding: 8px;
		max-width: 1200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

	}

	&__video {
		height: 0;
    	overflow: hidden;
    	padding-bottom: 56.25%;
		width: 100%;

    	iframe {
    		height: 100%;
    		left: 0;
    		position: absolute;
    		top: 0;
    		width: 100%;
    	}
	}
}



.c-footer {
	width: 100%;
	height: 500px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: -1;
	background: $c--coal url(../blancanieves/blancanieves-grupo.jpg);
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	&__access {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		position: absolute;
		bottom: 12px;
		text-align: center;

		a {
			color: white;
			opacity: 0.8;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: $fw-bold;
			font-size: 14px;

			&:hover {
				opacity: 1;
			}
		}
	}

	&__hashtag {
		position: absolute;
		top: 80px;
		color: white;
		font-weight: $fw-bold;
		text-align: center;
		margin-left: auto;
		width: 100%;
		font-size: 32px;
		
		@include responsive(tablet) {
			top: 32px;
			font-size: 60px;
		}
	}
}

.c-footer-empty {
	height: 500px;
	pointer-events: none;
}

.c-content {
	z-index: 2;
	position: relative;

	
	background: $c--coal url(../blancanieves/blancanieves-bg.jpg);
	background-position: center center;
	background-size: auto;
	background-repeat: repeat;
} 

.c-partner {
	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		align-items: center;
		justify-content: center;
		
		@include responsive(tablet) {
			gap: 64px;
		}
	}

	flex: 0 0 200px;
}