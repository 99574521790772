//colors 
$grey: #242424;
$white: #FFFFFF;
$red: #AD1818;
$red-transparent: rgba(173,24,24,0.95);
$lightgrey: #c4c4c4;
$bg: #F4F4F4;
$facebook: #3b5998 ;
$twitter: #1da1f2 ;
$instagram: #f56040;
$youtube: #cd201f;
$green: #009933;

//font weights
$fw-normal: 400;
$fw-bold: 700;

//widths
$w-mobile: 340px;
$w-mobile-landscape: 650px;
$w-tablet: 768px;
$w-tabletmax: 767px;
$w-desktop: 1024px;
$w-wide: 1200px;

//transitions
$transition: .3s;
$button-size: 3.2rem; 








